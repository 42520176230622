import React from 'react'
import { graphql } from 'gatsby'
import { trackGAEvent } from '@utils/functions'
import {
  AlternativeHero,
  Breadcrumbs,
  Container,
  Page,
  MoreProducts,
  ResponsiveImage,
} from '@components'
import Download from '@assets/images/download.svg'
import IconChat from '@assets/images/icon-chat.svg'
import {
  Aside,
  ContactButton,
  Content,
  Main,
  SimilarProducts,
} from './style'

const ProductTemplate = ({ data }) => {
  const { excerpt, fields, frontmatter, html } = data.markdownRemark
  const meta = {
    title: `${frontmatter.name} - Proizvod | Protupožarna zaštita`,
    description: excerpt,
    image: require(`@assets/images/proizvodi/${frontmatter.image}`),
    type: 'product',
  }

  const categories = frontmatter.productCategory.map((cat, i) => {
    if (i === 0) {
      return { link: `/proizvodi/${cat.slug}/`, name: cat.label }
    }
    return { link: `/proizvodi/${frontmatter.productCategory[0].slug}/${cat.slug}`, name: cat.label }
  })

  return (
    <Page
      meta={meta}
    >
      <Breadcrumbs
        links={[
          { link: '/', name: 'Početna'},
          { link: '/proizvodi/', name: 'Proizvodi'},
          ...categories,
          { link: `/proizvodi${fields.slug}`, name: frontmatter.name},
        ]}
      />
      <AlternativeHero
        slim
        title={frontmatter.name}
        subtitle={categories.map(c => c.name).join(' - ')}
      />
      <Container>
        <Main>
          <ContactButton
            href={`mailto:info@fsb-zagreb.hr?subject=Dostupnost proizvoda ${frontmatter.name}`}
            onClick={() => trackGAEvent({
              category: 'product',
              action: 'inquiry',
              label: frontmatter.name,
              isConversion: true,
            })}
          >
            <img src={IconChat} height="28px" alt="Pošalji upit" />
            <span>
              Pošalji upit o proizvodu
            </span>
          </ContactButton>
          <Aside>
            <ResponsiveImage
              alt={`${frontmatter.name} - ${frontmatter.productCategory[0].label}`}
              filename={`proizvodi/${frontmatter.image}`}
            />
            {
              frontmatter.documents &&
              frontmatter.documents.map(d => (
                <a href={require(`@assets/${d.link}`)} key={d.link}>
                  <img src={Download} height="20px" alt="Preuzmi datoteku" />
                  <span>
                    {d.value}
                  </span>
                </a>
              ))
            }
          </Aside>
          <Content>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </Content>
        </Main>
      </Container>
      <SimilarProducts>
        <MoreProducts
          activeCategory={frontmatter.productCategory[0].slug}
          excludes={[fields.slug]}
        />
      </SimilarProducts>
    </Page>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id } ) {
      html
      fields {
        slug
      }
      excerpt
      frontmatter {
        name
        productCategory {
          label
          slug
        }
        description
        image
        documents {
          link
          value
        }
      }
    }
  }
`

export default ProductTemplate
