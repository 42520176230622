import styled from 'styled-components'
import { transition } from '@styles/utils'
import { red, orange } from '@styles/theme'

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0 64px;

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 16px 0;
  }

  @media (max-width: 600px) {
    padding: 8px 0;
  }
`

export const Breadcrumbs = styled.div`
  margin: 32px 0 16px;

  a {
    color: inherit;
    font-weight: 500;
    font-size: 15px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const Content = styled.main`
  flex: 5;
  margin-left: 54px;
  
  @media (max-width: 1000px) {
    margin-left: 32px;
    width: calc(100% - 250px);
  }

  @media (max-width: 800px) {
    margin-left: 0;
    margin-top: 32px;
    width: 100%;
  }

  @media (max-width: 600px) {
    margin-top: 16px;
    font-size: 14px;
  }

  h2 {
    font-size: 20px;
    font-weight: 500;
    margin: 32px 0 16px;

    @media (max-width: 600px) {
      font-size: 18px;
      margin: 24px 0 8px;
    }

    :first-of-type {
      margin-top: 20px;
    }
  }

  h3 {
    font-size: 17px;
    font-weight: 500;
    margin: 22px 0 6px;

    @media (max-width: 600px) {
      font-size: 16px;
      margin: 14px 0 4px;
    }

    :first-of-type {
      margin-top: 14px;
    }
  }
  
  p {
    line-height: 1.7;
    margin: 12px 0 6px;
  }

  ul {
    li {
      margin-bottom: 8px;
    }
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
    font-size: 13px;
    border: 1px solid #ddd;
    width: 100%;

    @media (max-width: 600px) {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }


    tr {
      td {
        padding: 6px 6px;
        border-right: 1px solid #ddd;
        min-width: 120px;

        ul {
          margin: 0;
          margin-block-start: 0;
          padding-inline-start: 20px;

          li {
            margin-bottom: 4px;
          }
        }
      }
    }

    thead {
      @media (max-width: 600px) {
        width: 100%;
      }

      tr {
        th {
          padding: 4px 6px;
          background: #eee;
          font-weight: 500;
          border-bottom: 1px solid #ddd;
          text-align: left;
          border-right: 1px solid #ddd;
        }
      }
    }

    tbody {
      @media (max-width: 600px) {
        width: 100%;
      }

      tr {
        &:nth-of-type(even) {
          td {
            background: #f6f6f6;
          }
        }
      }
    }
  }
`
  
export const Aside = styled.aside`
  flex: 2;

  @media (max-width: 1000px) {
    min-width: 250px;
  }

  @media (max-width: 800px) {
    max-width: 300px;
  }

  > a {
    display: flex;
    align-items: center;
    padding: 13px;
    background: #ddd;
    transition: ${transition('background')};
    color: inherit;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 4px;


    img {
      opacity: 0.7;
      margin-right: 8px;
    }
    
    &:hover {
      background: ${orange};
    }
  }
`

export const ContactButton = styled.a`
  position: fixed;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 30px;
  right: 30px;
  padding: 16px 32px 16px 24px;
  box-sizing: border-box;
  border-radius: 4px;
  background: ${red};
  text-decoration: none;
  transition: ${transition('background,transform')};

  @media (max-width: 800px) {
    position: static;
    align-self: flex-start;
    margin: 16px 0;
    padding: 8px 24px 8px 16px;
  }

  span {
    color: #fff;
    font-size: 14px;
    margin-left: 8px;
  }

  &:hover {
    background: #d42626;
    transform: scale(1.05);

    @media (max-width: 800px) {
      transform: none;
    }
  }
`

export const SimilarProducts = styled.section`
  padding: 50px 0;
  background: #f4f4f4;
`